import React from "react";
import {
    LandingPageBg,
    LandingPageContainer,
    LandingPageContent,
    LandingPageImage, LandingPageText, LandingPageTitle, LandingPageSmallerText
} from "./LandingPageElements";
import logo from '../../images/ps_wallpaper.jpg';

const LandingPage = () => {
    return (
        <>
            <LandingPageContainer>
                <LandingPageBg>
                    <LandingPageImage src={logo} className="App-logo" alt="logo" />
                </LandingPageBg>
                <LandingPageContent>
                    <LandingPageTitle>

                    </LandingPageTitle>
                    <LandingPageText>
                        Simple session with a makeup artist?
                        <br />
                        movie with spectacular drone shots?
                        <br />
                        or eye-catching promo Video?  
                        <br />
                        Whatever you have in mind, our videography services
                        <br/>
                        can transform imagination into interaction
                        <br />
                        <br />
                        <br />
                    </LandingPageText>
                    <LandingPageSmallerText>
                        Photography and Videography services based in the UK
                    </LandingPageSmallerText>
                </LandingPageContent>
            </LandingPageContainer>
        </>
    )
}

export default LandingPage;
