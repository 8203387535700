import styled from "styled-components";
import "@fontsource/lexend";
import "@fontsource/bebas-neue";

export const LandingPageContainer = styled.div`
    background: #282c34;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 1300px;
    position: relative;
    z-index: 1;
    
    @media screen and (max-width: 900px) { 
        height: 1150px;
    }
    
    @media screen and (max-width: 800px) { 
        height: 1000px;
    }
        
    @media screen and (max-width: 700px) { 
        height: 835px;
    }
`;

export const LandingPageBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width:100%;
    height: 100%;
    overflow: hidden;
`;

export const LandingPageImage = styled.img`
    width:100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #282c34;
`;

export const LandingPageContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 830px;
    
   @media screen and (max-width: 768px) { 
        margin-top: 600px;
    }
`;

export const LandingPageTitle = styled.h1`
    color: #f0f0f0;
    font-size: 48px;
    text-align: center;
    font-family: "Lexend";
    
   @media screen and (max-width: 768px) { 
        font-size: 40px;
    }
    
   @media screen and (max-width: 480px) { 
        font-size: 32px;
    }
`;

export const LandingPageText = styled.div`
    color: #f3f3f3;
    font-size: 24px;
    text-align: center;
    font-family: "Bebas Neue";
   
   @media screen and (max-width: 480px) { 
        font-size: 16px;
    }
`;

export const LandingPageSmallerText = styled.div`
    color: #f3f3f3;
    font-size: 18px;
    text-align: center;
    font-family: "Bebas Neue";
   
   @media screen and (max-width: 480px) { 
        font-size: 14px;
    }
`;
