import styles from "styled-components";
import "@fontsource/lexend";

export const ContactContent = styles.div`
    font-family: "Lexend";  
    color: #fff;
    padding-left: 20px;
    z-index: 3;
    @media screen and (max-width: 768px) { 
        padding-left: 0px;
    }
`;

export const EmailLink = styles.a`
    color: #fff;
`;
export const ContactContentTitle = styles.h3``;
export const ContactContentText = styles.div``;

export const ContactFormWrapper = styles.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
    font-family: "Lexend";  
    color: #fff;
    padding-left: 10px;
    padding-right: 30px;
    z-index: 5;

    @media screen and (max-width: 768px) { 
        margin-top: 50px;
        padding-left: 10px;
    }
`;

export const ContactFormRow = styles.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
`;

export const ContactFormElement = styles.div`
    width: 100%;
`;

export const ContactFormElementInput = styles.input`
    width: 100%;
`;

export const ContactFormElementTextarea = styles.textarea`
    width: 100%;
`;

export const ContactFormElementSubmit = styles.button`
    color: #fff;
    border: 2px solid #fff;
    font-size: 1em;
    height: 30px;
    border-radius: 3px;
    background-color: transparent;
    width: 50%;
    
    &:hover {
        color: #282c34;
        background-color: #fff;
        transition: 0.2s ease-in-out;    
    }
`;

export const ContactImage = styles.img`
    height: 400px;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
    background: #282c34;
    border-radius: 40px;
    margin-top: 50px;

    @media screen and (max-width: 768px) { 
        margin-top: 50px;
        margin-bottom: 50px;
    }
`;