import React from "react";
import {
    ContactContent,
    ContactContentText,
    ContactContentTitle,
    EmailLink,
    ContactImage
} from "./ContactElements";
import ContactForm from "./ContactForm";
import contactPicture from '../../images/progress_studio.jpg';
import { Container, Row, Col } from "react-bootstrap";
import './Contact.css';

const Contact = () => {
    return (
        <>
            <Container fluid style={{backgroundColor: '#282c34', paddingTop: '20px', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
                <Row className="contact-row" style={{marginTop: '50px'}}>
                    <Col xs={12} md={6}>
                    <ContactContent>
                            <ContactContentTitle style={{paddingLeft: '10px'}}>
                                CONTACT
                            </ContactContentTitle>
                            
                            <ContactContentText style={{paddingLeft: '10px'}}>
                                <EmailLink href="mailto:progressstudio.info@gmail.com">progressstudio.info@gmail.com</EmailLink>
                                <br />
                                <br />
                                <EmailLink href="https://www.facebook.com/progress.studio.uk" target="_blank">https://www.facebook.com/progress.studio.uk</EmailLink>
                                <br />
                                <br />
                                <EmailLink href="https://www.instagram.com/progress.studio.uk" target="_blank">https://www.instagram.com/progress.studio.uk</EmailLink>
                                <br />
                                <br />
                                Photography and Videography services based in the UK
                            </ContactContentText>
                        </ContactContent>
                    </Col>
                    <Col xs={12} md={6}>
                    <ContactForm />
                    </Col>    
                </Row>             
                <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px'}}>
                    <ContactImage src={contactPicture} alt="logo" />
                </Row>
            </Container>    
            
        </>
    )
}

export default Contact;
