import React, {useState} from "react";
import {WorkContainer, WorkVideo, WorkVideoTitle} from "./WorkElements";

const Work = () => {
    const [width] = useState(window.innerWidth);
    const isMobile = width <= 768;
    const videoWidth = isMobile ? '360px' : '1024px';
    const videoHeight = isMobile ? '640px' : '768px';

    return (
        <>
            <WorkContainer>
                <WorkVideoTitle>P R O M O T I O N A L</WorkVideoTitle>
                {/*Iwona beauty clinic*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=iY1sSU2GSb8"} />
                <WorkVideoTitle>W E D D I N G</WorkVideoTitle>
                {/*Beth i Phillip*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=a--J1kZxwC4"} />
                <br />
                <br />
                {/*Kuba i Kasia*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=Uec9lYG_bnU"} />                
                <WorkVideoTitle>M U S I C</WorkVideoTitle>
                {/*Dont Koks */}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=8y1St65Dm8Q"} />
                <br />
                <br />
                {/*MATIS */}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=xbbaFXSrNtw"} />
                <WorkVideoTitle>T R A V E L</WorkVideoTitle>
                {/*Bieszczady*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=YmtxVLDUkdM"} />
                <WorkVideoTitle>I N D I V I D U A L</WorkVideoTitle>
                {/*RIP Daraas*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=6KHSJqveNx4"} />
                <WorkVideoTitle>E V E N T</WorkVideoTitle>
                {/*Bristol Purge*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=UiqblNPVmwU"} />
                <br />
                <br />
                {/*DJ*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=8zdjjFczqlc"} />
                <WorkVideoTitle>F A M I L Y</WorkVideoTitle>
                {/*Lili memories*/}
                <WorkVideo width={videoWidth} height={videoHeight} controls url={"https://www.youtube.com/watch?v=dz28PE7GjbM"} />
            </WorkContainer>
        </>
    )
}

export default Work;
