import React, {useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import MyNavBar from "../navbar/Navbar";
import {Outlet} from "react-router-dom";
import Footer from "../footer/Footer";

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggleIsOpen} />
            <MyNavBar toggle={toggleIsOpen} />
            <Outlet />
            <Footer />
        </>
    );
}

export default Home;
