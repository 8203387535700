import React, {useState} from "react"
import {
    ContactFormElement,
    ContactFormElementInput, ContactFormElementSubmit,
    ContactFormElementTextarea,
    ContactFormRow,
    ContactFormWrapper
} from "./ContactElements";

const ContactForm = () => {
    const EMAIL_SERVER_URL = 'https://progress-studio-email.progressstudio-factory.workers.dev/';
    // const EMAIL_SERVER_URL = 'http://0.0.0.0:8787';
    const formValuesInitState = {
        name: '',
        email: '',
        message: '',
    };

    // const corsHeaders = {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET, HEAD, POST, OPTIONS",
    //     "Access-Control-Allow-Headers": "Content-Type",
    // }

    const [formValues, setFormValues] = useState(formValuesInitState);
    const [hasSendEmail, setHasSendEmail] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formValues.email === '' || formValues.name === '') {
            return;
        }
        const init = {
            method: 'POST',
            body: JSON.stringify(formValues),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        await fetch(EMAIL_SERVER_URL, init)
            .then((response) => {
                console.log('SUCCESS', response);
            })
            .catch((error) => {
                console.log('ERROR', error);
            });
        setFormValues(formValuesInitState);
        setHasSendEmail(true);
    }

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const submitButtonTest = hasSendEmail ? 'We will contact you soon!' : 'Submit';

    return (
        <>
        <ContactFormWrapper>
            <form id="contact-form" onSubmit={handleSubmit} method="POST">
                
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="name">Name</label>
                            <ContactFormElementInput type="text" className="form-control" name="name" value={formValues.name}  onChange={handleChange}/>
                        </ContactFormElement>
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="email">Email address</label>
                            <ContactFormElementInput type="email" className="form-control" aria-describedby="emailHelp" name="email" value={formValues.email}  onChange={handleChange}/>
                        </ContactFormElement>
                    </ContactFormRow>
                    </ContactFormRow>
                    <ContactFormRow>
                        <ContactFormElement className="form-group">
                            <label htmlFor="message">Message</label>
                            <ContactFormElementTextarea className="form-control" rows="5" name="message" placeholder='Your message...' value={formValues.message}  onChange={handleChange}/>
                        </ContactFormElement>
                    </ContactFormRow>
                    <ContactFormElementSubmit type="submit">{`${submitButtonTest}`}</ContactFormElementSubmit>
            </form>
            </ContactFormWrapper>
        </>
    )
}

export default ContactForm;
