import {MobileIcon, Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu, NavRouterLinks} from "./NavBarElements";
import {FaInstagram, FaFacebook, FaBars} from "react-icons/fa"
import logo from "../../images/ps_white_logo_small.png"

const MyNavBar = ({toggle}) => {
    return (
        <Nav>
            <NavbarContainer>
                <NavLogo to={'/'}><img src={logo} alt="logo" /></NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavRouterLinks to='/work'>VIDEO</NavRouterLinks>
                    </NavItem>
                    <NavItem>
                        <NavRouterLinks to='/contact'>CONTACT</NavRouterLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks href={'https://www.instagram.com/progress.studio.uk'} target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks href={'https://www.facebook.com/progress.studio.uk'} target="_blank" rel="noopener noreferrer">
                            <FaFacebook />
                        </NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    );
}

export default MyNavBar;
