import styled from "styled-components";
import "@fontsource/lexend";

export const FooterWrapper = styled.div`
    background: #282c34;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    font-size: 1.0rem;
    font-weight: 500;
    
    @media screen and (max-width: 768px) { 
        font-size: 0.75rem;
    }
`;

export const FooterItem = styled.div`
    padding: 0 8px;
`;
