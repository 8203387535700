import styles from "styled-components"
import ReactPlayer from "react-player";
import "@fontsource/lexend";

export const WorkContainer = styles.div`
    width: 100%;
    height: auto;
    background-color: #282c34;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const WorkVideoTitle = styles.div`  
    margin-top: 50px;
    margin-bottom: 50px;
    color: #f3f3f3;
    font-size: 80px;
    text-align: center;
    font-family: "Bebas Neue";
    
   @media screen and (max-width: 768px) { 
        font-size: 60px;
    }
    
   @media screen and (max-width: 480px) { 
        font-size: 52px;
    }
`;

export const WorkVideo = styles(ReactPlayer)`  
`;
